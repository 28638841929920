export default {
  'home.name': 'Trang Chủ',
  'home.title': 'Tài liệu lưu trữ',
  'home.fileName': 'Tên File',
  'home.fullPath': 'Đường dẫn',
  'home.contentType': 'Loại file',
  'home.createdAt': 'Ngày tạo',
  'home.confirm.title.delete': 'Xóa File',
  'home.confirm.content.delete': 'Bạn đồng ý xóa những file này ?',
  'home.create': 'Thêm mới file',
  'home.action': 'Hành Động',
};
