import { english, vietnam } from '@assets/images';
import ISelect from '@core/select';

export const LANGUAGE: ISelect<string>[] = [
  { value: 'vi', label: 'VNM', icon: vietnam },
  { value: 'en', label: 'ENG', icon: english },
];

export const allSelect: ISelect = { label: 'common.all', value: undefined };

export const idClubAdmin = '6bec45d3-83a0-4160-865d-2ccfb128e306';

export const idAdministrator = '79775bc2-1899-459b-a9eb-0e26822ae40e';

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL,
  GAME_URL: 'https://mizuku.dev.altasoftware.vn',
  APP_NAME: process.env.APP_NAME || 'AltaFrontend',
  LOGIN_PAGE: '/#/login',
  SSO_PAGE: '/#',
  TINY_KEY: process.env.TINY_KEY || '',
};

export default CONFIG;
