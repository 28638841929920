/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/named */
import '@shared/assets/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import '@styles/styles.scss';

import { ConfigProvider } from 'antd';
import lodash from 'lodash';
import React, { memo, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import store from '@core/store/redux';
import locale from '@locale/index';
import { TokenSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import PrivatePage from '@routers/component/PrivatePage';

import PublicPage from '../routers/component/PublicPage';

const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  return <>{statusLogin ? <PrivatePage /> : <PublicPage />}</>;
});

// For Test
const App: React.FC = () => {
  const token = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language]);

  useEffect(() => {
    if (lodash.isEmpty(token)) {
      if (location.pathname === '/privacy-policy') {
        return;
      }
      navigate('/login');
      return;
    }
  }, [token]);

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ConfigProvider locale={memoLangData}>
        <MainView statusLogin={token !== ''} />
      </ConfigProvider>
    </IntlProvider>
  );
};

export default App;
