/* eslint-disable @typescript-eslint/comma-dangle */
import { routerForgot, routerForgotToken } from '@view/Auth/ForgotPassword/router';
import { routerLogin, routerLoginHome } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profile/router';
import { routerResetForgotPass } from '@view/Auth/ResetForgotPass/router';
import {
  routerCalendar,
  routerEventCalendarAdd,
  routerEventCalendarEdit,
  routerEventCalendarInfo,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
} from '@view/Calendar/router';
import { routerCategory } from '@view/Category/router';
import { routerFormClubs, routerFormClubsAdd } from '@view/Clubs/component/FormClubs/router';
import {
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
} from '@view/Clubs/component/ListExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerListExecutiveCommittee } from '@view/Clubs/component/ListExecutiveCommittee/router';
import {
  routerFormClubsMember,
  routerFormClubsMemberInfo,
} from '@view/Clubs/component/ListMembers/component/Member/component/FormMember/router';
import {
  routerFormClubsMemberPersonal,
  routerFormClubsMemberPersonalInfo,
} from '@view/Clubs/component/ListMembers/component/MemberPersonal/component/FormMemberPersonal/router';
import {
  routerFormClubsPartner,
  routerFormClubsPartnerInfo,
} from '@view/Clubs/component/ListMembers/component/Partner/component/FormPartner/router';
import { routerListClubsMemberTab } from '@view/Clubs/component/ListMembers/router';
import { routerClubs } from '@view/Clubs/router';
import { routerContacts } from '@view/Contacts/router';
import { routerCost } from '@view/Cost/router';
import { routerHome } from '@view/Home/router';
import {
  routerFormExecutiveCommittee,
  routerFormExecutiveCommitteeInfo,
} from '@view/MemberAndPartner/component/ExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerHistoryFees } from '@view/MemberAndPartner/component/HistoryFees/router';
import { routerHistorySponsors } from '@view/MemberAndPartner/component/HistorySponsors/router';
import {
  routerFormMember,
  routerFormMemberInfo,
} from '@view/MemberAndPartner/component/Member/component/FormMember/router';
import {
  routerFormMemberPersonal,
  routerFormMemberPersonalInfo,
} from '@view/MemberAndPartner/component/MemberPersonal/component/FormMemberPersonal/router';
import {
  routerFormPartner,
  routerFormPartnerInfo,
} from '@view/MemberAndPartner/component/Partner/component/FormPartner/router';
import { routerMemberAndPartnerTabs } from '@view/MemberAndPartner/router';
import {
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
} from '@view/Notification/router';
import { routerPageError } from '@view/PageError/router';
import { routerRoles, routerRolesAdd, routerRolesEdit, routerRolesInfo } from '@view/Roles/router';
import { routerSetting } from '@view/Setting/router';
import { routerSponsor } from '@view/Sponsor/router';
import { routerUser } from '@view/User/router';

import { routerPrivacyPolicy } from '../view/PrivacyPolicy/router';
import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerViewProfile,
  routerHome,
  routerUser,
  routerRoles,
  routerRolesAdd,
  routerRolesEdit,
  routerRolesInfo,
  routerClubs,
  routerMemberAndPartnerTabs,
  routerCost,
  routerSponsor,
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
  routerCalendar,
  routerFormClubsAdd,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
  routerEventCalendarAdd,
  routerEventCalendarEdit,
  routerEventCalendarInfo,
  routerCategory,
  routerFormExecutiveCommittee,
  routerFormMember,
  routerFormPartner,
  routerSetting,
  routerFormExecutiveCommitteeInfo,
  routerFormMemberInfo,
  routerFormPartnerInfo,
  routerHistoryFees,
  routerHistorySponsors,
  routerFormClubs,
  routerContacts,
  routerFormMemberPersonal,
  routerFormMemberPersonalInfo,
  routerListExecutiveCommittee,
  routerListClubsMemberTab,
  routerFormClubsMember,
  routerFormClubsMemberInfo,
  routerFormClubsMemberPersonal,
  routerFormClubsMemberPersonalInfo,
  routerFormClubsPartner,
  routerFormClubsPartnerInfo,
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
  routerPrivacyPolicy,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerLoginHome,
  routerLogin,
  routerPrivacyPolicy,
  routerForgot,
  routerForgotToken,
  routerResetForgotPass,
  routerPageError,
];
