import store from '@core/store/redux';
import UserEntity from '@modules/user/entity';

import profileStore, { setToken } from './profileStore';
import authenticationRepository, { ILoginDTO } from './repository';

const authenticationPresenter = { ...authenticationRepository };

authenticationPresenter.login = async (payload: ILoginDTO, remember = false) => {
  const token = await authenticationRepository.login(payload);
  store.dispatch(
    setToken({ token: token.accessToken, refreshToken: token.refreshToken, remember }),
  );
  return token;
};

authenticationPresenter.getProfile = () => {
  return authenticationRepository.getProfile().then((user: UserEntity) => {
    const rolePermissions = user.role?.rolePermissions.map(it=>it.permissionCode);
    store.dispatch(profileStore.actions.fetchProfile({ user, listPermissionCode:rolePermissions }));
    return user;
  });
};

authenticationPresenter.resetToken = payload => {
  return authenticationRepository
    .resetToken(payload)
    .then((res: { accessToken: string; refreshToken: string }) => {
      store.dispatch(profileStore.actions.resetToken(res));
      return Promise.resolve(res);
    });
};

export default authenticationPresenter;
