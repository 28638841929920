import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

const mainRoute = 'notification';

export const routerNotificationAdd: IRouter = {
  path: `/${mainRoute}/add`,
  loader: React.lazy(() => import('./Page')),
  exact: true,
  name: 'notification.add.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.NOTIFICATIONS_CREATE,
};

export const routerNotificationEdit: IRouter = {
  path: `/${mainRoute}/edit/:id`,
  loader: React.lazy(() => import('./Page')),
  exact: true,
  name: 'notification.edit.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.NOTIFICATIONS_UPDATE,
};

export const routerNotificationInfo: IRouter = {
  path: `/${mainRoute}/info/:id`,
  loader: React.lazy(() => import('./Page/PageInfo')),
  exact: true,
  name: 'notification.info.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.NOTIFICATIONS_VIEW,
};

export const routerNotification: IRouter = {
  path: `/${mainRoute}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'notification.name', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.NOTIFICATIONS_SEARCH,
  menu: {
    activePath: 'notification',
    icon: <Icon.Bell />,
  },
};
