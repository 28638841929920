export default {
  'fields.create': 'Thêm Lĩnh vực',
  'fields.code': 'Mã Lĩnh vực',
  'fields.name': 'Tên Lĩnh vực',
  'fields.description': 'Mô tả',
  'fields.update': 'Chỉnh sửa Lĩnh vực',
  'fields.information': 'Thông tin Lĩnh vực',
  'fields.createdAt': 'Thời gian tạo',
  'fields.action': 'Hành động',
  'fields.confirm.title.delete': 'Bạn có muốn xóa những Lĩnh vực này ?',
  'fields.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
  'fields.careers': 'Ngành',
  'fields.fieldRelations': 'Nhóm con',
  'fields.fieldRelations.add': 'Thêm nhóm con',
  'fields.fieldRelations.title': 'Nhóm con',
  'fields.fieldRelations.name': 'Tên Nhóm con',
  'fields.fieldRelations.description': 'Mô tả',
  'fields.fieldRelations.name.required': 'Vui lòng nhập tên nhóm con',
  'fields.fieldRelations.description.required': 'Vui lòng nhập thông tin nhóm con',
  'fields.fieldRelations.code': 'Mã Nhóm con',
  'fields.fieldRelations.code.required': 'Vui lòng nhập thông mã nhóm con',
};
