export const logo = require('./logo.png');
export const logoLogin = require('./logoLogin.png');
export const error = require('./error.png');
export const imgAvatar = require('./avatar.jpg');
export { default as rightImgLogin } from './right-login.png';

export { default as vietnam } from './vietnam.png';
export { default as english } from './english.png';
export { default as sponsors_icon } from './Sponsors_icon.png';
export { default as fees_icon } from './Fees_icon.png';
