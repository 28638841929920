export default {
  'clubs.title': 'Club',
  'clubs.name': 'Club',
  'clubs.heading': 'Club list',
  'clubs.code': 'Club code',
  'clubs.enterpriseName': 'Club name',
  'clubs.fieldId': 'Field',
  'clubs.marketId': 'Market',
  'clubs.targetCustomer': 'Targeted customer',
  'clubs.enterpriseScale': 'Business scale',
  'clubs.status': 'Activity',
  'clubs.action': 'Act',
  'clubs.amountMember': 'Number of members',
  'clubs.field': 'Field',
  'clubs.market': 'Market',
  'clubs.status.param': '{status,select, 1 {Active} 2 {Not active} other {Unknown}}',
  'clubs.view': 'Club details',
  'clubs.edit': 'Club editing',
  'clubs.delete.title': 'Delete the club',
  'clubs.delete.content': 'Do you want to delete this club?',
  'clubs.non-delete.title': 'Cannot delete the club',
  'clubs.non-delete.content': "Can't delete the active club",

  'clubs.memberFields': 'Field',

  'clubs.add': 'Add a new club',
  'clubs.label.code': 'Club code',
  'clubs.placeholder.code': 'Enter the club code',
  'clubs.label.represent': 'Representative',
  'clubs.placeholder.represent': 'Enter the name of the representative',
  'clubs.label.name': 'Club name',
  'clubs.placeholder.name': 'Enter the name of the club',
  'clubs.label.idCard': 'ID Card',
  'clubs.placeholder.idCard': 'Enter card code',
  'clubs.label.enterpriseName': 'Business name (Vietnamese)',
  'clubs.placeholder.enterpriseName': 'Enter the name of the business',
  'clubs.label.enterpriseEnglishName': 'Business name (English)',
  'clubs.placeholder.enterpriseEnglishName': 'Enter the name of the business',
  'clubs.label.enterpriseAcronymsName': 'Business name (abbreviation)',
  'clubs.placeholder.enterpriseAcronymsName': 'Enter the name of the business',
  'clubs.label.headOfficeAddress': 'Head office address',
  'clubs.placeholder.headOfficeAddress': 'Enter the head office address',
  'clubs.label.transactionOfficeAddress': 'Transaction office address',
  'clubs.placeholder.transactionOfficeAddress': 'Enter the address of the transaction office',
  'clubs.label.taxCode': 'Tax code',
  'clubs.placeholder.taxCode': 'Enter the tax code',
  'clubs.label.phoneNumber': 'Phone number',
  'clubs.placeholder.phoneNumber': 'Enter your phone number',
  'clubs.label.website': 'Website',
  'clubs.placeholder.website': 'Enter the website',
  'clubs.label.fanpage': 'fanpage',
  'clubs.placeholder.fanpage': 'Enter fanpage',
  'clubs.label.birthday': 'Founding',
  'clubs.placeholder.birthday': 'Enter the founding date',
  'clubs.label.charterCapital': 'Authorized capital',
  'clubs.placeholder.charterCapital': 'Enter the number of charter volts',
  'clubs.label.turnover': 'Revenue before joining',
  'clubs.placeholder.turnover': 'Intract revenue in 2021',
  'clubs.label.type': 'Branch',
  'clubs.placeholder.type': 'Choose industry',
  'clubs.label.fieldRelationIds': 'Field',
  'clubs.placeholder.fieldRelationIds': 'Choose the field',
  'clubs.label.marketId': 'Market',
  'clubs.placeholder.marketId': 'Choose the market',
  'clubs.label.targetCustomerId': 'Targeted customer',
  'clubs.placeholder.targetCustomerId': 'Select target customers',
  'clubs.enterpriseScale.value-0': '50 - 100 people ',
  'clubs.enterpriseScale.value-1': '100 - 200 people',
  'clubs.enterpriseScale.value-2': '200 - 500 people',
  'clubs.enterpriseScale.value-3': 'Over 500 people',
  'clubs.label.certificateId': 'Certificate',
  'clubs.placeholder.certificateId': 'Select certificates',
  'clubs.label.prize': 'Prize',
  'clubs.placeholder.prize': 'Enter the prize',
  'clubs.label.certificateOfMerit': 'Merit',
  'clubs.placeholder.certificateOfMerit': 'Enter a certificate of merit, certificate of merit',
  'clubs.label.leaderInfo.name': 'First and last name',
  'clubs.placeholder.leaderInfo.name': 'Enter Full name',
  'clubs.label.leaderInfo.dateOfBirth': 'Date of birth',
  'clubs.placeholder.leaderInfo.dateOfBirth': 'Enter the date of birth',
  'clubs.label.leaderInfo.position': 'Position',
  'clubs.placeholder.leaderInfo.position': 'Enter the position',
  'clubs.label.leaderInfo.phoneNumber': 'Phone number',
  'clubs.placeholder.leaderInfo.phoneNumber': 'Enter your phone number',
  'clubs.label.leaderInfo.gender': 'Gender',
  'clubs.label.leaderInfo.gender.male': 'Male',
  'clubs.label.leaderInfo.gender.female': 'Female',
  'clubs.label.leaderInfo.emailAddress': 'Direct contact email',
  'clubs.placeholder.leaderInfo.emailAddress': 'Enter your email address',
  'clubs.label.connectInfos.name': 'First and last name',
  'clubs.placeholder.connectInfos.name': 'Enter Full name',
  'clubs.label.connectInfos.position': 'Position',
  'clubs.placeholder.connectInfos.position': 'Enter the position',
  'clubs.label.connectInfos.phoneNumber': 'Phone number',
  'clubs.placeholder.connectInfos.phoneNumber': 'Enter your phone number',
  'clubs.label.connectInfos.gender': 'Gender',
  'clubs.label.connectInfos.gender.male': 'Male',
  'clubs.label.connectInfos.gender.female': 'Female',
  'clubs.label.connectInfos.emailAddress': 'Direct contact email',
  'clubs.placeholder.connectInfos.emailAddress': 'Enter your email address',
  'clubs.label.detailSingin': 'Login information',
  'clubs.placeholder.detailSingin': 'Login information',
  'clubs.label.careers': 'Branch',
  'clubs.placeholder.careers': 'Choose industry',
  'clubs.member': 'List of members',
  'clubs.list-members': 'List of members',
  'clubs.ExecutiveCommittee': 'List of executive committees',
  'clubs.executive-committee.prorogue': 'Prorogue',
  'clubs.list-executive-committee': 'List of executive committees',
  'clubs.executive-committee.name': 'Name',
  'clubs.executive-committee.position': 'Position',
  'clubs.executive-committee.action': 'Action',
  'clubs.label.emailAddress': 'Email',
  'clubs.placeholder.emailAddress': 'Enter email address',
  'clubs.label.establishmentDecision': 'Establishment Decision',
  'clubs.placeholder.establishmentDecision': 'Enter Establishment Decision',
  'clubs.executive-committees.label.code': 'Code',
  'clubs.executive-committees.placeholder.code': 'Enter code',
  'clubs.executive-committees.label.name': 'Name',
  'clubs.executive-committees.placeholder.name': 'Enter name',
  'clubs.executive-committees.label.gender': 'Gender',
  'clubs.executive-committees.gender.male': 'Male',
  'clubs.executive-committees.gender.female': 'Female',
  'clubs.executive-committees.label.dayOfBirth': 'Day of birth',
  'clubs.executive-committees.placeholder.dayOfBirth': 'Enter Day of birth',
  'clubs.executive-committees.label.phoneNumber': 'Phone number',
  'clubs.executive-committees.placeholder.phoneNumber': 'Enter Phone number',
  'clubs.executive-committees.label.workUnit': 'Work unit',
  'clubs.executive-committees.placeholder.workUnit': 'Enter work unit',
  'clubs.executive-committees.label.position': 'Position',
  'clubs.executive-committees.placeholder.position': 'Enter Position',
  'clubs.executive-committees.main.title-1': '2. Personal infomation',
  'clubs.executive-committees.main.title-2': '2. Clubs infomation',
  'clubs.executive-committees.label.title': 'Title',
  'clubs.executive-committees.placeholder.title': 'Enter title',
  'clubs.executive-committees.label.prorogues': 'Prorogues',
  'clubs.executive-committees.placeholder.prorogues': 'Enter Prorogues',
  'clubs.executive-committees.main.title-3': '3. Infomation',
  'clubs.executive-committees.label.status': 'Status',
  'clubs.executive-committees.label.status.active': 'Active',
  'clubs.executive-committees.label.status.not-active': 'Not active',
  'clubs.executive-committees.add': 'Add executive committee',
  'clubs.executive-committees': 'Executive committee',
  'formClubsMember.main.title-9': '9. Member information',
  'clubs.label.dayOfBirth': 'Day of birth',
  'clubs.placeholder.dayOfBirth': 'Enter Day of birth',

  // Form Member
  'formClubs.header': 'Add a unit club',
  'formClubs.main.title-1': '1.Basic information',
  'formClubs.main.title-2': '2.Industry field ',
  'formClubs.main.title-3': '3.Market',
  'formClubs.main.title-4': '4.Unit leaders',
  'formClubs.main.title-5': '5.In charge of connection ',
  'formClubs.main.title-6': '6.Login information ',
};
