export default {
  'contacts.name': 'Liên hệ',
  'contacts.title': 'Liên hệ',
  'contacts.switchboard': 'Tổng đài',
  'contacts.website': 'Website',
  'contacts.fanpage': 'Fanpage',
  'contacts.email': 'Email',
  'contacts.address': 'Địa chỉ',
  'contacts.longitude': 'Kinh độ',
  'contacts.latitude': 'Vĩ độ',
  'contact-page.message.email': 'Vui lòng nhập email',
  'contact-page.message.switchboard': 'Vui lòng nhập tổng dài',
  'contact-page.message.format.switchboard': 'Tổng dài không đúng định dạng',
  'contact-page.message.format.email': 'Email không đúng định dạng',
};
