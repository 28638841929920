export default {
  'home.name': 'Home page',
  'home.title': 'Archives',
  'home.fileName': 'File name',
  'home.fullPath': 'Path',
  'home.contentType': 'File type',
  'home.createdAt': 'Date created',
  'home.confirm.title.delete': 'Delete the file',
  'home.confirm.content.delete': 'Do you agree to delete these files?',
  'home.create': 'Add new file',
  'home.action': 'Action',
};
